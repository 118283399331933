import React, {
  useEffect,
  //  useState 
} from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import axios from 'axios';
import { hot } from 'react-hot-loader/root';
import { Provider, useSelector } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom';
import { ReactReduxFirebaseProvider, isLoaded } from 'react-redux-firebase';
import { ConfigProvider, Spin } from 'antd';
import { getBaseURL } from "./Api/ApiConfig";
import store, { rrfProps } from './redux/store';
// import Auth from './routes/auth';
import './static/css/style.css';
// import { ProtectedRoute } from './components/utilities/protectedRoute';
import config from './config/config';
import Default from './routes/default';
import Menu from './routes/Menu';
import 'antd/dist/antd.less';

const { theme } = config;

const ProviderConfig = () => {
  // const [loginOption, setLoginOption] = useState();

  const { rtl, topMenu, darkMode, auth } = useSelector(state => {
    return {
      darkMode: state.ChangeLayoutMode.data,
      rtl: state.ChangeLayoutMode.rtlData,
      topMenu: state.ChangeLayoutMode.topMenu,
      isLoggedIn: state.auth.login,
      auth: state.fb.auth,
    };
  });
  // const isLoggedIn = localStorage.getItem('user_token');
  const urlPath = window.location.search;
  const menuToken = urlPath.split('=');

  useEffect(() => {
    async function restaurant() {
      const headers = {}; 
      headers.Authorization = `Bearer ${menuToken[1]}`;
      if (menuToken[1] !== undefined) {
        localStorage.setItem('menu_token', menuToken[1])
        const response = await axios.get(`${getBaseURL()}/smartPrism/getRestaurant`, { headers });
        localStorage.setItem('restaurant', response.data.data.restaurantId)
        if (response.data.statusCode === 200) {

          const data = await axios.get(`${getBaseURL()}/smartPrism/getByRestaurantId/${response.data.data.restaurantId}`)
          localStorage.setItem('MV', data.data.data.menuView)
          localStorage.setItem('MC', data.data.data.mainColor)
          localStorage.setItem('MCLT', data.data.data.mainColorLight)
          localStorage.setItem('MCDK', data.data.data.mainColorDark)
          localStorage.setItem('BG', data.data.data.backgroundTheme)
          localStorage.setItem('CBG', data.data.data.cartTheme)
          localStorage.setItem('TXT', data.data.data.textTheme)
          localStorage.setItem('AC', data.data.data.actionColor)
          localStorage.setItem('CTXT', data.data.data.textContrast)
          // setLoginOption(data.data.data.loginOption)
          localStorage.setItem('MName', data.data.data.menuNameColor)
          localStorage.setItem('MPrice', data.data.data.mrpColor)
          localStorage.setItem('MDesc', data.data.data.descColor)
        }
      }
    }
    restaurant();
    // }, [loginOption]);
  }, []);

  return (
    <ConfigProvider direction={rtl ? 'rtl' : 'ltr'}>
      <ThemeProvider theme={{ ...theme, rtl, topMenu, darkMode }}>
        <ReactReduxFirebaseProvider {...rrfProps}>
          {!isLoaded(auth) ? (
            <div className="spin">
              <Spin />
            </div>
          ) : (
            <>
              {
                (urlPath.includes("?access_token=") && !urlPath.includes("undefined")) ?
                  (
                    // loginOption === "DISABLED" ?
                    //   (
                    <Router >
                      {/* this part of code for without Login / DISABLED */}
                      <Route path="/menu" component={Menu} />
                      <Redirect to={`/menu?access_token=${menuToken[1]}`} />
                    </Router>
                    // )
                    // :
                    // (
                    //   loginOption === "ENABLED" &&
                    //   (
                    //     <Router >
                    //       {/* this part of code for with Login / ENABLED */}
                    //       {!isLoggedIn ? <Route path="/" component={Auth} /> : <ProtectedRoute path="/menu" component={Menu} />}
                    //       {isLoggedIn && <Redirect to={`/menu?access_token=${menuToken[1]}`} />}
                    //     </Router>
                    //   )
                    // )
                  )
                  :
                  (
                    <Router>
                      <Route path="/" component={Default} />
                    </Router>
                  )

              }
            </>
          )}
        </ReactReduxFirebaseProvider>
      </ThemeProvider>
    </ConfigProvider>
  );
}

function App() {
  return (
    <Provider store={store}>
      <ProviderConfig />
    </Provider>
  );
}

export default hot(App);