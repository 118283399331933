// For Development Server
const URL = process.env.REACT_APP_DINEEASY; 

// For Production Server
// const URL = process.env.REACT_APP_BASE_URL_PRODUCTION; 

const middleURL = '/api'
const apiBaseURL = URL + middleURL;

const getBaseURL = () => {
    return apiBaseURL;
}

export { getBaseURL }

// import axios from "axios";
// const getAxiosInstance = () => {
//     return axios.create({baseURL: apiBaseURL});
// }